import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// Utils
import { sanitizeTitle } from "../../../utils/sanitize"
import handlePrimaryCategory from "../../../utils/handlePrimaryCategory"

// Components
import Category from "../../../components/post/category"
import Excerpt from "../../../components/post/excerpt"
import Author from "../../../components/post/author"
import Background from "../../../components/post/background"

export default ({
  post: { featured_media, categories, title, excerpt, author, slug, meta },
}) => {
  const category = handlePrimaryCategory(
    false,
    categories,
    meta.primary_category
  )

  return (
    <Article>
      <Background
        image={
          featured_media && featured_media.localFile && featured_media.localFile.childImageSharp
          ? featured_media.localFile.childImageSharp.fluid
          : false
        }
        title={title}
      />
      <Category name={category.name} slug={category.slug} />
      <Title>{sanitizeTitle(title)}</Title>
      <Excerpt content={excerpt} />
      <Author
        name={author.name}
        slug={author.slug}
        avatar={
          author.mpp_avatar.full && author.mpp_avatar.full.localFile && author.mpp_avatar.full.localFile.childImageSharp
          ? author.mpp_avatar.full.localFile.childImageSharp.fixed
          : false
        }
      />
      <PostLink to={`/${slug}`} aria-label={`Read more about ${title}`} />
    </Article>
  )
}

// Styled Components
const Article = styled.article`
  display: grid;
  grid-row-gap: 1.25rem;
  height: 100%;
  position: relative;
`
const PostLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`
const Title = styled.h2`
  font-size: 2rem;
  transition: all 0.2s;
  article:hover & {
    color: ${props => props.theme.color.blue["400"]};
  }
  @media (min-width: 992px) {
    font-size: 2.25rem;
  }
`
