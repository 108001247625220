import React, { useState, useEffect } from "react"
import styled from "styled-components"

// Utils
import handlePrimaryCategory from "../../../../utils/handlePrimaryCategory"

// Components
import Item from "./item"

export default ({ 
  posts: {
    nodes: posts
  },
  featured
}) => {
  const [tab, setActiveTab] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (tab === 1) {
      setLoading(true)
    }
  }, [tab])
  return (
    <List>
      {featured ? (
        <Nav>
          <Tab className={tab === 0 && `active`}>
            <Button onClick={() => setActiveTab(0)}>{`Popular`}</Button>
          </Tab>
          <Tab className={tab === 1 && `active`}>
            <Button
              onClick={() => setActiveTab(1)}
            >{`Articles for you`}</Button>
          </Tab>
        </Nav>
      ) : (
        <Description>{`Popular articles`}</Description>
      )}
      {tab === 0 ? (
        posts.map(
          ({ id, title, categories, meta, author, featured_media, slug }) => {
            const category = handlePrimaryCategory(
              false,
              categories,
              meta.primary_category
            )

            return (
              <Item
                key={id}
                title={title}
                category={category}
                author={author}
                background={
                  featured_media && featured_media.localFile && featured_media.localFile.childImageSharp
                  ? featured_media.localFile.childImageSharp.fluid
                  : false
                }
                link={slug}
                featured={featured}
              />
            )
          }
        )
      ) : (
        <React.Fragment>
          <Item loading={loading} />
          <Item loading={loading} />
          <Item loading={loading} />
        </React.Fragment>
      )}
    </List>
  )
}

// Styled Components
const List = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 2rem;
`
const Description = styled.div`
  font-size: 1rem;
  font-weight: 500;
`
const Nav = styled.ul`
  list-style: none;
  display: flex;
`
const Tab = styled.li`
  &:first-child {
    margin-right: 1rem;
  }
`
const Button = styled.button`
  padding: 0.75rem 1rem;
  background-color: transparent;
  font-size: 1rem;
  border: none;
  outline: 0;
  color: inherit;
  cursor: pointer;
  transition: all 0.2s;
  color: ${props => props.theme.color.neutral["600"]};
  ${Tab}.active & {
    background-color: ${props => props.theme.color.blue["50"]};
  }
  @media (min-width: 992px) {
    font-size: 1.125rem;
  }
`
