import React, { useState } from "react"
import { graphql } from "gatsby"

// Layout Components
import Layout from "../../components/layout/index"
import SEO from "../../components/seo/index"
import Container from "../../components/container"

// Components
import Pagination from "../../components/pagination/index"
import Intro from "./intro/index"
import Posts from "../../components/post/grid"

export default ({
  data: {
    allWordpressPost: { nodes: posts },
    featuredPost,
    popularPosts
  },
  pageContext: { pageNumber, slug, numberOfPages },
}) => {

  const [currentPage, setCurrentPage] = useState(pageNumber)

  return (
    <Layout
      pathPrefix={slug}
      pageNumber={pageNumber}
      numberOfPages={numberOfPages}
    >
      <SEO
        title={
          pageNumber !== 0 &&
          `Proof Blog - Page ${pageNumber + 1} of ${numberOfPages}`
        }
        paged={pageNumber !== 0 && pageNumber + 1}
      />
      <Container
        sm={`
          grid-row-gap: 3rem;
          padding-top: 1.5rem;
        `}
        lg={`
          padding-top: 2rem;
        `}
      >
        {pageNumber === 0 && 
          <Intro 
            featuredPost={featuredPost}
            popularPosts={popularPosts}
          />
        }
        <Posts posts={posts} showAd />
        {numberOfPages > 1 && (
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            numberOfPages={numberOfPages}
          />
        )}
      </Container>
    </Layout>
  )
}

// Page Query
export const pageQuery = graphql`
  query homePostQuery(
      $skip: Int!, 
      $limit: Int!, 
      $featured: String!
      $popular: [String!]!
    ) {
    allWordpressPost(
      sort: {order: DESC, fields: date}
      filter: { 
        id: { 
          ne: $featured,
          nin: $popular
        }
      }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        id
        title
        excerpt
        slug
        ...postCategory
        featured_media {
          ...postCardBg
        }
        author {
          name
          slug
          ...authorAvatar
        }
      }
    }
    featuredPost: wordpressPost(id: {eq: $featured}) {
      id
      title
      excerpt
      slug
      ...postCategory
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 585, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      author {
        name
        slug
        ...authorAvatar
      }
    }
    popularPosts: allWordpressPost(
      filter: {
        id: {
          in: $popular
        }
      }
    ) {
      nodes {
        id
        title
        excerpt
        slug
        ...postCategory
        featured_media {
          localFile {
            childImageSharp {
              fluid(maxWidth: 120, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        author {
          slug
          name
        }
      }
    }
  }
`
