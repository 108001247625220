import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Facebook as Loader } from "react-content-loader"

// Components
import Category from "../../../../components/post/category"
import Title from "../../../../components/post/title"
import Background from "../../../../components/post/background"
import Author from "../../../../components/post/author"

export default ({
  loading,
  featured,
  title,
  author,
  category,
  background,
  link,
}) => {
  return loading ? (
    <Load />
  ) : (
    <>
      <Article>
        <Content>
          <Title content={title} format={`list`} />
          <Footer>
            <Category
              plalette={featured && "neutral"}
              name={category.name}
              slug={category.slug}
            />
            <Author hideAvatar name={author.name} slug={author.slug} />
          </Footer>
        </Content>
        <Background image={background} title={title} />
        <PostLink to={`/${link}`} aria-label={`Read more about ${title}`} />
      </Article>
    </>
  )
}

// Styled Components
const Article = styled.article`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 7.5rem);
  grid-column-gap: 1.25rem;
  align-items: start;
  position: relative;
`
const PostLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`
const Content = styled.div`
  display: grid;
  grid-row-gap: 1.25rem;
`
const Footer = styled.div`
  display: flex;
  align-items: center;
`
const Load = styled(Loader)``
