import React from "react"
import styled from "styled-components"

// Components
import Featured from "./featured"
import List from "./list/index"

export default ({
  featuredPost,
  popularPosts
}) => {
  return (
    <Wrapper>
      <Featured post={featuredPost} />
      <List posts={popularPosts} />
    </Wrapper>
  )
}

// Styled Components
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 3rem;
  @media (min-width: 992px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 3rem;
    align-items: start;
  }
  @media (min-width: 1200px) {
    grid-template-columns: minmax(0, 1.25fr) minmax(0, 1fr);
  }
`
